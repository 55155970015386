import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { buildQueryString, extractReadableLabel } from "../utils/buildQueryString";

// Create an adapter for the orders list
const ordersListAdapter = createEntityAdapter({
  selectId: (order) => order.id,
});

const initialState = ordersListAdapter.getInitialState();

// Define the slice
export const ordersListSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ✅ Fetch list of orders
    getOrdersList: builder.query({
      query: (filters) => buildQueryString("orders", filters),
      transformResponse: (responseData) => {
        const loadedOrdersList = responseData.data.map((item) => ({
          ...item,
          urlLabel: item.url ? extractReadableLabel(item.url) : "",
        }));

        return ordersListAdapter.setAll(initialState, loadedOrdersList);
      },
      providesTags: (result) =>
        result
          ? [
              { type: "orders", id: "ordersList" },
              ...result.ids.map((id) => ({ type: "orders", id })),
            ]
          : [{ type: "orders", id: "ordersList" }],
    }),

    // ✅ Fetch individual order details
    getOrderById: builder.query({
      query: (orderId) => `orders/${orderId}`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, orderId) => [{ type: "orders", id: orderId }],
    }),
  }),
});

// Export hooks for API calls
export const { useGetOrdersListQuery, useGetOrderByIdQuery } = ordersListSlice;

// Select the order filter state
const orderFilter = (state) => state.orderFilter;

// ✅ Select filtered orders from the state
const selectOrderListResult = createSelector(
  [orderFilter, (state) => state],
  (filter, state) =>
    ordersListSlice.endpoints.getOrdersList.select({
      status: filter.status,
      search: filter.search,
    })(state)
);

const selectOrdersListData = createSelector(
  [selectOrderListResult],
  (orderListResult) => orderListResult?.data ?? initialState
);

// ✅ Adapter selectors for orders
export const {
  selectAll: selectOrders,
  selectById: selectOrderById,
  selectIds: selectOrderIds,
} = ordersListAdapter.getSelectors((state) => selectOrdersListData(state));
