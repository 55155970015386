import React, { useCallback, useEffect } from "react";
import classes from "./couponFilterPage.module.css";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SearchInput } from "../../../component/searchInput/SearchInput";
import { openModal } from "../../../store/modalSlice";

export const CouponFilterPage = ({ filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  // useEffect(() => {
  //   const statusParam = searchParams.get("status");
  //   const searchParam = searchParams.get("search");

  //   if (filters.status !== statusParam || filters.search !== searchParam) {
  //     dispatch(
  //       setRetailerFilter({
  //         status: statusParam || null,
  //         search: searchParam || null,
  //       })
  //     );
  //   }
  // }, [dispatch, searchParams, filters]);

  const updateFilterParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (value) {
      newSearchParams.set(key, value);
    } else {
      newSearchParams.delete(key);
    }
    setSearchParams(newSearchParams);
    // dispatch(setRetailerFilter({ [key]: value || null }));
  };

  const handleSelection = (identifier, selectedOptionId) => {
    updateFilterParams(identifier, selectedOptionId);
  };

  const handleSearch = (searchText) => {
    // updateFilterParams("search", searchText);
  };

  const handleClearText = () => {
    // Remove "search" from URL params
    // const newSearchParams = new URLSearchParams(searchParams);
    // newSearchParams.delete("search");
    // setSearchParams(newSearchParams);
    // // Reset "search" in the Redux store
    // dispatch(setRetailerFilter({ search: null }));
  };

  const handleOpenModal = useCallback(
    (operation) => {
      dispatch(
        openModal({
          component: "DynamicForm",
          uiData: {
            heading: `${operation} Coupon`,
            primaryButtonLabel: `${operation} Coupon`,
            isGridRequired: true,
          },
          configData: {},
          operationType: operation,
          module: "coupon",
        })
      );
    },
    [dispatch]
  );

  return (
    <div className={classes.box}>
      <div className={classes.box__content}>
        <SearchInput
          placeholder="Search by Coupon code..."
          onSearch={handleSearch}
          // searchTextFrmStore={filters.search}
          // searchFilter={filters.search != null}
          onClear={handleClearText}
        />
      </div>

      <button className={classes.box__btn} onClick={() => handleOpenModal("Add")}>
        Add Coupon
      </button>
    </div>
  );
};
