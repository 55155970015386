import { createSlice } from "@reduxjs/toolkit";

const couponFilterSlice = createSlice({
  name: "couponFilter",
  initialState: {
    coupon_code: null,
    search: null,
  },
  reducers: {
    setCouponFilter: (state, action) => {
      state.coupon_code = action.payload.coupon_Code || null;
      state.search = action.payload.search || null;
    },

    clearCouponFilters: (state) => {
      state.coupon_code = null;
      state.search = null;
    },
  },
});

export const { setCouponFilter, clearCouponFilters } = couponFilterSlice.actions;

export const selectCouponState = (state) => state.couponFilter;

export default couponFilterSlice.reducer;
