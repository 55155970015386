import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../../store/modalSlice";
import { useGetOrderByIdQuery } from "../../../services/orderApiSlice";
import { orderTableColumnsConfig } from "./orderTableColumnsConfig";
import { Table } from "../../../component/table/Table";

export const OrderTablePage = ({ data }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const [orderId, setOrderId] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();

  // Fetch order details when orderId updates
  const { data: orderDetails, isSuccess, isFetching } = useGetOrderByIdQuery(orderId, {
    skip: !orderId, // Prevent unnecessary API calls
  });

  // Handle row click - set orderId first
  const handleOpenModal = (rowData, identifier) => {
    setOrderId(rowData.id); // Step 1: Set orderId
    setSelectedRow({ rowData, identifier }); // Step 2: Store rowData for later use
  };

  // Step 3: Open modal only when API has returned the latest data
  useEffect(() => {
    if (!isFetching && isSuccess && orderDetails && selectedRow && orderId === selectedRow.rowData.id) {
      dispatch(
        openModal({
          component: "DynamicForm",
          uiData: {
            heading: "View And Verify",
            primaryButtonLabel: "Verify",
            isGridRequired: true,
          },
          configData: {
            ...orderDetails, // Now has the latest data
            identifier: selectedRow.identifier,
          },
          operationType: selectedRow.identifier,
          module: "order",
        })
      );
      setSelectedRow(null); // Reset after modal opens
    }
  }, [isFetching, isSuccess, orderDetails, orderId, dispatch, selectedRow]);

  // Set Table Columns
  useEffect(() => {
    setColumnDefs(orderTableColumnsConfig["order"](handleOpenModal));
  }, []);

  return <Table data={data} columns={columnDefs} />;
};
