import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { buildQueryString } from "../utils/buildQueryString";
import { dateFormatter } from "../utils/dateFormatter";

// Create an adapter for the coupons list
const couponsListAdapter = createEntityAdapter({
  selectId: (coupon) => coupon.id,
});

const initialState = couponsListAdapter.getInitialState();

// Define the slice
export const couponsListSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // ✅ Fetch list of coupons
    getCouponsList: builder.query({
      query: (filters) => buildQueryString("coupons", filters),
      transformResponse: (responseData) => {
        const loadedCouponsList =
          responseData.data?.map((coupon) => ({
            ...coupon,
            start_date: coupon.start_date
              ? dateFormatter(coupon.start_date)
              : "N/A",
            end_date: coupon.end_date ? dateFormatter(coupon.end_date) : "N/A",
          })) ?? [];

        return couponsListAdapter.setAll(initialState, loadedCouponsList);
      },
      providesTags: (result) =>
        result?.ids
          ? [
              { type: "coupons", id: "couponsList" },
              ...result.ids.map((id) => ({ type: "coupons", id })),
            ]
          : [{ type: "coupons", id: "couponsList" }],
    }),

    // ✅ Fetch individual coupon details
    getCouponById: builder.query({
      query: (couponId) => `coupons/${couponId}`,
      transformResponse: (responseData) => responseData.data,
      providesTags: (result, error, couponId) => [
        { type: "coupons", id: couponId },
      ],
    }),

    // ✅ Create a new coupon
    createCoupon: builder.mutation({
      query: (newCoupon) => ({
        url: "coupons",
        method: "POST",
        body: newCoupon,
      }),
      invalidatesTags: [{ type: "coupons", id: "couponsList" }],
    }),

    // ✅ Update a coupon (PUT method)
    updateCoupon: builder.mutation({
      query: ({...updatedCoupon}) => ({
        url: `coupons`,
        method: "PUT",
        body: updatedCoupon,
      }),
      invalidatesTags: (result, error, { couponId }) => [
        { type: "coupons", id: couponId },
        { type: "coupons", id: "couponsList" },
      ],
    }),

    // ✅ Delete a coupon
    deleteCoupon: builder.mutation({
      query: (couponId) => ({
        url: `coupons/${couponId}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "coupons", id: "couponsList" }],
    }),
  }),
});

// Export hooks for API calls
export const {
  useGetCouponsListQuery,
  useGetCouponByIdQuery,
  useCreateCouponMutation,
  useUpdateCouponMutation,
  useDeleteCouponMutation,
} = couponsListSlice;

// Select the coupon filter state
const couponFilter = (state) => state.couponFilter;

// ✅ Select filtered coupons from the state
const selectCouponListResult = createSelector(
  [couponFilter, (state) => state],
  (filter, state) =>
    couponsListSlice.endpoints.getCouponsList.select({
      coupon_code: filter.coupon_code,
      search: filter.search,
    })(state) ?? initialState // Ensure initial state fallback
);

const selectCouponsListData = createSelector(
  [selectCouponListResult],
  (couponListResult) => couponListResult?.data ?? initialState
);

// ✅ Adapter selectors for coupons
export const {
  selectAll: selectCoupons,
  selectById: selectCouponById,
  selectIds: selectCouponIds,
} = couponsListAdapter.getSelectors((state) => selectCouponsListData(state));
