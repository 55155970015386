import { createSlice } from "@reduxjs/toolkit";

const orderIdSlice = createSlice({
  name: "orderId",
  initialState: {
    orderId: null,
  },
  reducers: {
    setOrderId: (state, action) => {
      state.orderId = action.payload; // Directly assign payload, handles undefined
    },
    clearOrderId: (state) => {
      state.orderId = null;
    },
  },
});

export const { setOrderId, clearOrderId } = orderIdSlice.actions;
export const selectOrderIdState = (state) => state.orderId.orderId;

export default orderIdSlice.reducer;
