import React from "react";
import classes from "./couponPage.module.css";
import { CouponFilterPage } from "./couponFilter/CouponFilterPage";
import { CouponTablePage } from "./couponTable/CouponTablePage";
import { selectCouponState } from "../../store/couponFilterSlice";
import { useSelector } from "react-redux";
import {
  selectCoupons,
  useGetCouponsListQuery,
} from "../../services/couponApiSlice";
import { CategoryPageSkeleton } from "../../component/skeleton/CategoryPageSkeleton";

export const CouponPage = () => {
  const appliedFilters = useSelector(selectCouponState);
  const { isSuccess } = useGetCouponsListQuery(appliedFilters);

  const tableData = useSelector(selectCoupons);

  console.log("coupons ", tableData ? tableData : "loading");

  return isSuccess ? (
    <div className={classes.box}>
      <CouponFilterPage filters={appliedFilters} />
      <CouponTablePage data={tableData} />
    </div>
  ) : (
    <CategoryPageSkeleton />
  );
};
