export const dateFormatter = (timestamp) => {
  if (!timestamp) return "Invalid Date";
  
  const date = new Date(Number(timestamp));
  if (isNaN(date.getTime())) return "Invalid Date";
  
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = String(date.getFullYear()).slice(-2);
  
  return `${day}/${month}/${year}`;
};



export const formatToYYYYMMDD = (dateString) => {
  if (!dateString) return "";
  const parts = dateString.split("/");
  if (parts.length === 3) {
    let [day, month, year] = parts;
    year = "20" + year; // Convert 2-digit year to 4-digit
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }
  return dateString; // If already in YYYY-MM-DD format
};


export const convertToTimestamp = (dateString) => {
  return new Date(dateString).getTime();
};