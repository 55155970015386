import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { couponTableColumnsConfig } from "./couponTableColumnDef";
import { Table } from "../../../component/table/Table";
import { openModal } from "../../../store/modalSlice";

export const CouponTablePage = ({ data }) => {
  const [columnDefs, setColumnDefs] = useState([]);
  const dispatch = useDispatch();
  console.log(data)

  const handleOpenModal = useCallback(
    (rowData, operation) => {
      console.log(rowData);
      console.log(operation);
      dispatch(
        openModal({
          component: "DynamicForm",
          uiData: {
            heading: `${operation} Coupon`,
            primaryButtonLabel: `${operation} Coupon`,
            isGridRequired: true,
          },
          configData: {
            ...rowData,
          },
          operationType: operation,
          module: "coupon",
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    setColumnDefs(couponTableColumnsConfig["coupon"](handleOpenModal));
  }, [handleOpenModal]);
  return <Table data={data} columns={columnDefs} />;
};
