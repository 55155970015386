import { createColumnHelper } from "@tanstack/react-table";
const columnHelper = createColumnHelper();

export const couponTableColumnsConfig = {
  coupon: (handleOpenModal) => [
    columnHelper.accessor("code", {
      header: "Code",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    // columnHelper.accessor("descrption", {
    //   header: "Description",
    //   cell: (info) => info.getValue(),
    //   footer: (props) => props.column.id,
    // }),
    columnHelper.accessor("min_order_value", {
      header: "Minimum Order value",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor("discount_value", {
      header: "Discount Value",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor("start_date", {
      header: "Start Date",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor("end_date", {
      header: "End Date",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor("max_redeemable_amount", {
      header: "Redeemable Amount",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.accessor("discount_type", {
      header: "Discount Type",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
    }),
    columnHelper.display({
      id: "actions",
      header: <div style={{ textAlign: "center" }}>Action</div>,
      cell: (props) => {
        const { is_active } = props.row.original;

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <button
              style={{
                color: "#46CD80",
                fontSize: "12px",
                lineHeight: "12px",
                fontWeight: 500,
                fontFamily: "Poppins, sans",
                padding: "8px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => handleOpenModal(props.row.original, "Edit")}
            >
              Edit
            </button>
            |
            <button
              style={{
                color: is_active ? "#FE0000" : "#E6E6E6",
                fontSize: "14px",
                lineHeight: "14px",
                fontWeight: 500,
                fontFamily: "Poppins, sans",
                padding: "8px",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => handleOpenModal(props.row.original, "Delete")}
              disabled={!is_active}
            >
              Delete
            </button>
          </div>
        );
      },
    }),
  ],
};
